<template>
  <div class="content">
    <div><img class="icon" src="../assets/img/dd.png"></div>
    <div class="tip">请在钉钉客户端打开</div>
  </div>
</template>
<script>
export default {
name: "Error"
}
</script>

<style scoped>
  .content{
    width: 100%;
    text-align: center;
    padding-top: 300px;
  }
  .icon{
    width: 64px;
  }
  .tip{
    margin-top: 20px;
  }
</style>